.searchContainer {
  margin-top: 5rem;
  animation: 1.5s downUp;
  transition: 1.5s all ease-in-out;

  form {
    display: flex;
    box-shadow: 0px 3px 1rem rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 100px;
    overflow: hidden;
    position: relative;
  }

  .searchBar {
    font-size: 1.6rem;
    width: 50rem;
    height: 5rem;
    border-radius: 0.3rem;
    padding-left: 2.5rem;
    outline: 0;
    border: 0;

    &:focus {
      ~ .searchOutline {
        width: 100%;
      }
    }
  }

  .searchOutline {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 0%;
    height: 3px;
    margin: 0 auto;
    transition: 0.5s width ease-in-out;
    box-shadow: 0px 0px 5px var(--color-orange);
    background-color: rgb(255, 176, 4);
  }

  .searchButton {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5rem;
    width: 7rem;
    padding-right: 0.5rem;
    outline: 0;
    border: none;
    cursor: pointer;
    transition: 0.2s ease-out all;
    font-size: 1.6rem;
    color: var(--color-gray50);
    z-index: 1;

    &:hover {
      background-color: var(--color-orange);
      color: var(--color-white);
    }
  }
}

@keyframes downUp {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}