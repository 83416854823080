* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

::selection {
  background-color: var(--color-orange);
  color: var(--color-white);
}

:root {
  --color-white: #FFFFFF;

  --color-black: #000000;
  --color-black50: #333333;

  --color-orange: #ffa500;
  --color-orange50: #ff9900;

  --color-gray50: #888888;

  --color-red: #dd4f4f;
}

html {
  font: 300 62.5% Poppins;
}

body {
  overflow-y: scroll;
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.lazy-load-image-background {
  display: inherit !important;
}