.imageContainer {
  break-inside: avoid;
  margin-bottom: 3rem;
  animation: 0.3s fadeIn;
  will-change: transform;
}

.imageCard {
  display: inline-flex;
  cursor: pointer;
  position: relative;
  
  img {
    width: 100%;
    border-radius: 0.5rem;
    position: relative;
  }

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0);
    transition: 0.3s ease background-color;
  }
  
  &:hover {
    &::after {
      background-color: rgba(0, 0, 0, 0.137);
    }
  }
}

.imageDescription {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.6rem;
  font-weight: 400;
  margin-top: 0.5rem;

  i {
    color: var(--color-orange);
  }
}

@keyframes fadeIn {
  0% {
    transform: scale(0.8);
  }

  100% {
    transform: scale(1);
  }
}