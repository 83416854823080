.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s opacity fadeIn;
  animation: 0.3s fadeIn;
}

.modalBox {
  display: flex;
  justify-content: center;
  align-items: center;

  width: calc(100vh - 300px);

  img {
    max-height: 90vh;
    border-radius: 5px;
    box-shadow: 0px 0px 0.5rem rgba(0, 0, 0, 0.2);
  }
}

.modalClose {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: transparent;
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1;
  border-radius: 50%;
  cursor: pointer;
  font-size: 2rem;
  border: 1px solid #fff
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}